
import { Box, Button, Card, CardContent, Divider, IconButton, Snackbar, Typography } from '@material-ui/core';
import React from 'react';
import { useParams } from "react-router-dom";
import FileDragDropUploadArea from '../components/filedragdropuploadarea';
import FileList from '../components/filelist';
import Toolbar from '@material-ui/core/Toolbar';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import axios from 'axios';
import { Alert } from '@material-ui/lab';
import { auth } from 'firebase';
import LoadingIndicator from '../helper/loadingindicator';
import {config} from './../config'
import { RefreshOutlined } from '@material-ui/icons';

function CategorieView(props)
{
    const [newDocumentMode, setNewDocumentMode] = React.useState(false);
    const [categorie, setCategorie] = React.useState();
    const [refresh, setRefresh] = React.useState(false);
    const [uploadFinished, setUploadFinished] = React.useState(false);
    const [firebaseuseridtoken, setFirebaseuseridtoken] = React.useState()
    let { categorieid } = useParams();

    React.useEffect(() => {
        auth()
            .currentUser
            .getIdToken()
            .then((idToken) => {
                setFirebaseuseridtoken(idToken);
            })
    }, [])

    React.useEffect(() => {
        if (categorieid && firebaseuseridtoken)
        {
            setNewDocumentMode(false);
            axios.get(config.apiUrl+"categories/"+categorieid, {headers: {'Authorization': `Bearer ` + firebaseuseridtoken}}).then((response) => {
                setCategorie(response.data);
            }).catch((error) => 
            {
                // if (error.response.status === 401)
                // {

                // }
            }
            )
        }
    },[categorieid, firebaseuseridtoken])

    function onUploadFinished()
    {
        setUploadFinished(true);
        setNewDocumentMode(false)
        setRefresh(true);
        props.reloadCategorieView();
    }

    function reloadData()
    {
        setRefresh(true);   
    }

    if (!categorie)
    {
        return <LoadingIndicator loadingText={"Kategorieansicht wird geladen..."}></LoadingIndicator>
    }

    return(
        <div>
            <Snackbar open={uploadFinished} autoHideDuration={3000} onClose={() => setUploadFinished(false)} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert onClose={() => setUploadFinished(false)} severity="success">
                    Dokument wurde erfolgreich hinzugefügt
                </Alert>
            </Snackbar>
            <Toolbar>
                <Typography variant="h5">{categorie.bezeichnung}</Typography>
                <IconButton color="primary" aria-label="upload picture" component="span" onClick={() => reloadData()}>
                    <RefreshOutlined />
                </IconButton>
                <Box flex={1}></Box>
                <Button
                    variant="outlined"
                    color="inherit"
                    onClick={() => setNewDocumentMode(!newDocumentMode)}
                    startIcon={!newDocumentMode ? <AddCircleOutlineIcon /> : <RemoveCircleOutlineIcon />}
                >
                    Neu
                </Button>
            </Toolbar>
            <Divider></Divider>
            {newDocumentMode &&
                <Card style={{marginBottom: 10}}>
                    <CardContent>
                        <FileDragDropUploadArea categorieid={categorieid} handleUploadFinished={onUploadFinished} firebaseuseridtoken={firebaseuseridtoken}></FileDragDropUploadArea>
                    </CardContent>
                </Card>            
            }  
            <Box pt={2}></Box>
            <FileList categorieid={categorieid} firebaseuseridtoken={firebaseuseridtoken} refresh={refresh} handleLoadFinished={() => {setRefresh(false);props.reloadCategorieView();}}></FileList>
        </div>
    );
}

export default CategorieView;