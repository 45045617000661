import React from 'react';
import { Avatar, Button, Divider, Grid,  List, ListItem, ListItemAvatar, Typography} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import axios from 'axios';
import AttachFileIcon from '@material-ui/icons/AttachFile';

import AWS from 'aws-sdk'
import LoadingIndicator from '../helper/loadingindicator';

const S3_BUCKET ='mydocs365';
const REGION ='eu-central-1';

AWS.config.update({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_ID
})

const myBucket = new AWS.S3({
  params: { Bucket: S3_BUCKET},
  region: REGION,
})

function FileDragDropUploadArea(props) {

  const [inUpload, setInUpload] = React.useState(false);
  const [fileList, setFileList] = React.useState([]);
  const [progress, setProgress] = React.useState();

  function handleDragIn(event)
  {
    event.preventDefault()
    event.stopPropagation()
  }

  function handleDrop(event)
  {
    event.preventDefault()
    event.stopPropagation()
    var myfiles = [...fileList];
    for (let index = 0; index < event.dataTransfer.files.length; index++) {
        var fileItem = {bezeichnung: event.dataTransfer.files[index].name.split('.').slice(0, -1).join('.'), fileName: event.dataTransfer.files[index].name, fileSize: (event.dataTransfer.files[index].size / 1048576).toFixed(2), file: event.dataTransfer.files[index]};
        myfiles.push(fileItem);
    }
    setFileList(myfiles)
  }

  function handeBildButton(event)
  {
    event.preventDefault()
    event.stopPropagation()
    var myfiles = [...fileList];
    for (let index = 0; index <  event.target.files.length; index++) {
        var fileItem = {bezeichnung:  event.target.files[index].name.split('.').slice(0, -1).join('.'), fileName:  event.target.files[index].name, fileSize: ( event.target.files[index].size / 1048576).toFixed(2), file: event.target.files[index]};
        myfiles.push(fileItem);
    }
    setFileList(myfiles)
  }

  function changeFileBezeichnung(xBezeichnung, xFile)
  {
    var fileEntry = fileList.find(x => x.fileName == xFile.fileName);
    fileEntry.bezeichnung = xBezeichnung;
    setFileList([...fileList]);
  }

  async function saveDocuments()
  {
    setInUpload(true);
    let promises = []
    for (let index = 0; index < fileList.length; index++) {
      uploadFile(fileList[index].file);
      promises.push(axios.post("https://3orsy34dh4.execute-api.eu-central-1.amazonaws.com/files", {"id": fileList[index].fileName,"categorie": {"id": props.categorieid},"name": fileList[index].fileName}, {headers: {'Authorization': `Bearer ` + props.firebaseuseridtoken}})); 
    }
    axios.all(promises)
    .then(responseArr => {

      });
  }

  function uploadFile(file)
  {
    const params = {
        ACL: 'public-read',
        Body: file,
        Bucket: S3_BUCKET,
        Key: props.categorieid+"/"+file.name
    };
    myBucket.putObject(params)
    .on('httpUploadProgress', (evt) => {
        setProgress(Math.round((evt.loaded / evt.total) * 100))
    })
    .send((err) => {
        if (err) 
        {
          console.log(err)
        }else
        {
          setInUpload(false);
          setFileList([]);
          if (props.handleUploadFinished)
            props.handleUploadFinished();
        }
    })
}

  function getFileList()
  {
    return (
      fileList.map((fileItem, index) => 
        <div>
          <ListItem key={index} alignItems="flex-start">
            <ListItemAvatar>
              <Avatar>
                <AttachFileIcon />
              </Avatar>
            </ListItemAvatar>
            <Grid spacing={3} container direction="column">
              <Grid item>
                <TextField fullWidth label="Bezeichnung" id="bezeichnung" value={fileItem.bezeichnung} onChange={(event) => changeFileBezeichnung(event.target.value, fileItem)} InputLabelProps={{shrink: true}}></TextField>
              </Grid>
              <Grid item>
                <TextField fullWidth readonly label="Name" id="name" value={fileItem.fileName} InputLabelProps={{shrink: true}}></TextField>
              </Grid>
              <Grid item>
                <TextField fullWidth readonly label="Größe (MB)" id="size" value={fileItem.fileSize} InputLabelProps={{shrink: true}}></TextField>
              </Grid>
            </Grid>
          </ListItem>
          {index < fileList.length-1 &&
            <Divider></Divider>               
          }             
        </div>
      )
    )
  }

  return (
    <div>
          {inUpload && 
            <LoadingIndicator loadingText={progress}></LoadingIndicator>
          }
          <Grid container spacing={2}>
            <Grid item>
            <Button variant={"outlined"} onClick={() => saveDocuments()} disabled={fileList.length === 0}>Speichern</Button>
            </Grid>
            <Grid item>
              <Button variant={"outlined"} onClick={() => setFileList([])}>Abbrechen</Button>
             </Grid>
          </Grid>
          <Grid container xs={12} style={{paddingBottom: 10, paddingTop: 10}}>
            <Grid item xs={12}>
              <Divider></Divider>
            </Grid>
          </Grid>
          <Grid container alignItems={"center"} justify={"center"} xs={12}>
            <Grid item xs={12}>
              <div onDragOver={(event) => handleDragIn(event)} onDrop = {(event) => handleDrop(event)} 
                style={{
                  border: 'dashed grey 4px',
                  backgroundColor: 'rgba(255,255,255,.8)',
                  top: 0,
                  bottom: 0,
                  left: 0, 
                  right: 0,
                  zIndex: 9999,
                  width: "100%",
                  height: 300
                }}>
                  <Grid container style={{padding: 10}} justify="center">
                    <Grid item>
                      <Typography variant={"h5"}>Drag'n Drop Dateien</Typography>
                    </Grid>
                  </Grid>
                  <Grid container style={{padding: 10}} justify="center">
                    <Grid item>
                      <Typography variant={"h5"}>oder</Typography>
                    </Grid>
                  </Grid>
                  <Grid container style={{padding: 10}} justify="center">
                    <Grid item>
                      <input
                          accept="image/*"
                          id="contained-button-file"
                          style={{display: 'none'}}
                          type="file"
                          multiple
                          onChange={(event) => handeBildButton(event)}
                      />
                      <label htmlFor="contained-button-file">
                          <Button variant="contained" color="primary" component="span" >
                              Bildauswahl
                          </Button>                 
                      </label>
                    </Grid> 
                  </Grid>                 
                </div>
              </Grid>
            </Grid>
            <Grid container alignItems={"center"} justify={"center"}>
              <Grid item xs={12}>
                <List>
                  {getFileList()}
                </List>
              </Grid>
            </Grid>
    </div>

  );
}

export default FileDragDropUploadArea;
