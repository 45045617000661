import { Card, CardContent, Grid, List, ListItem, Typography, ListItemAvatar, ListItemText, Avatar, Divider, Paper } from '@material-ui/core';
import axios from 'axios';
import React from 'react';
import FileDragDropUploadArea from './filedragdropuploadarea';
import {config} from './../config'
import moment from "moment";
import FileItem from './fileitem';

export default function Dashboard(props)
{
    const [files, setFiles] = React.useState();
    const [currentSelectedDocument, setCurrentSelectedDocument] = React.useState();

    React.useEffect(() => {
        //loadOffeneDocs();
    },[])

    function onUploadFinished()
    {

    }

    function handleDocumentClicked(xFile)
    {
        setCurrentSelectedDocument(xFile);
    }

    function getOffeneDocs()
    {
        if (!files)
            return (<div></div>);
        var fileList = files.map((file, index) => 
            <div key={index}>
              <ListItem alignItems="flex-start" dense button onClick={() => handleDocumentClicked(file) }>
                <ListItemAvatar>
                  <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
                </ListItemAvatar>
                <ListItemText
                  primary={file.name}
                  secondary={
                    <React.Fragment>
                      {moment(file.uploaddatetime).format("DD.MM.yyyy - hh:mm")} Uhr
                    </React.Fragment>
                  }
                />
              </ListItem>
              <Divider variant="inset" component="li" />  
            </div>)

        return fileList;
    }

    function loadOffeneDocs()
    {
      axios.get(config.apiUrl+"files/0", {headers: {'Authorization': `Bearer ` + props.firebaseUserToken}}).then((response) => {
        setFiles(response.data);
      });
    }
    if (currentSelectedDocument)
        return (
            <Paper>
                {currentSelectedDocument && 
                    <FileItem categorieid={0} currentSelectedDocument={currentSelectedDocument} firebaseuseridtoken={props.firebaseUserToken} closeFileItem={() => setCurrentSelectedDocument()}></FileItem>
                }
            </Paper>     
        )
    return (
        <div>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <FileDragDropUploadArea categorieid={0} handleUploadFinished={onUploadFinished} firebaseuseridtoken={props.firebaseUserToken}></FileDragDropUploadArea>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <Card> 
                        <CardContent>
                            <Typography variant={"h5"}>Offene Dokumente</Typography>
                            <List>
                                {getOffeneDocs()}
                            </List>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </div>
    )
}