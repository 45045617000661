import React from "react";
import { Route, Redirect } from "react-router-dom";

export default function PrivateRoute({
  component: Component,
  authorized,
  firebaseUserToken,
}) {
    return (
      <Route render={(props) => (
        authorized === true && firebaseUserToken //&& firebaseUser && firebaseUser.emailVerified === true && user
          ? <Component {...props} firebaseUserToken={firebaseUserToken}/>
          : <Redirect to='/login' />
      )} />
  );
}