import React from 'react';
import axios from 'axios';
import {config} from './../config'
import { makeStyles } from '@material-ui/core/styles';
import { Avatar, Paper, Divider, Grid, List, ListItem, ListItemAvatar, ListItemText, TextField, Card, CardContent, Typography, Box} from '@material-ui/core';
import moment from "moment";
import { Alert } from '@material-ui/lab';
import {Split} from '@geoffcox/react-splitter'
import FileItem from './fileitem';
import {
  BrowserView,
  MobileView
} from "react-device-detect";
import FileDragDropUploadArea from './filedragdropuploadarea';
import LoadingIndicator from '../helper/loadingindicator';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '98%',
    minHeight: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: 'inline',
  },
}));

function FileList(props)
{
    const classes = useStyles();
    const [originFiles, setOriginFiles] = React.useState();
    const [files, setFiles] = React.useState();
    const [currentSelectedDocument, setCurrentSelectedDocument] = React.useState();
    const [error, setError] = React.useState();
    const [splitPrimarySize, setSplitPrimarySize] = React.useState("300");

    React.useEffect(() => {
      setCurrentSelectedDocument();
      setFiles();
      loadData();
      if (localStorage.getItem('mydocs365_primary_panel_size'))
      {
        setSplitPrimarySize(localStorage.getItem('mydocs365_primary_panel_size'));
      }
    },[props.categorieid])
  
    React.useEffect(() => {
      if (props.refresh)
      {
        setFiles();
        loadData();
      }
    },[props.refresh])
  
    function handleDocumentClicked(xFile)  {
      setCurrentSelectedDocument(xFile)
    };

    function loadData()
    {
      axios.get(config.apiUrl+"files/"+props.categorieid, {headers: {'Authorization': `Bearer ` + props.firebaseuseridtoken}}).then((response) => {
        setFiles(response.data.Items);
        setOriginFiles(response.data.Items)
        if (props.handleLoadFinished)
          props.handleLoadFinished();
      });
    }

    function handleSearchChanged(xText)
    {
      var filteredItems = originFiles.filter(x => x.name.toLowerCase().includes(xText.toLowerCase()));
      setFiles(filteredItems);
    }

    function onMeasuredSizesChanged(sizes)
    {
      if (sizes.primary < 300)
        return;
      localStorage.setItem('mydocs365_primary_panel_size', sizes.primary);
      setSplitPrimarySize(sizes.primary);
    };

    function onUploadFinished()
    {

    }

    function loadFileList()
    {
      var fileList = files.map((file, index) => 
        <div key={index}>
          <ListItem alignItems="flex-start" dense button onClick={() => handleDocumentClicked(file) }>
            <ListItemAvatar>
              <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
            </ListItemAvatar>
            <ListItemText
              primary={file.id}
              secondary={
                <React.Fragment>
                  {moment(file.uploaddatetime).format("DD.MM.yyyy - hh:mm")} Uhr
                </React.Fragment>
              }
            />
          </ListItem>
          <Divider variant="inset" component="li" />  
        </div>
      )
      return fileList;
    }

    return (
      <div>
        <Grid container spacing={2}>
            {error &&
              <Alert severity="error">{error}</Alert>
            }
        </Grid>
        <Grid container spacing={2}>
          <Grid xs={12} item >
            <BrowserView>
                <Split initialPrimarySize={`${splitPrimarySize}px`} minPrimarySize='300px' onMeasuredSizesChanged={(sizes) => onMeasuredSizesChanged(sizes)}>
                  <div>
                    <TextField style={{width: `${splitPrimarySize}px`}} size={"small"} id={props.categorieid} label="Suche" variant="outlined" onChange={(event) => handleSearchChanged(event.target.value)}/>
                    <Box pt={1}></Box>
                    {files && files.length > 0 &&
                        <List className={classes.root}>
                          {loadFileList()}
                        </List>
                    }
                    {!files &&
                      <LoadingIndicator loadingText={"Dateien werden geladen..."}></LoadingIndicator>
                    }
                  </div>
                  <div>
                  {props.newDocumentMode && 
                      <Card style={{marginBottom: 10}}>
                          <CardContent>
                              <FileDragDropUploadArea categorieid={props.categorieid} handleUploadFinished={onUploadFinished} firebaseuseridtoken={props.firebaseuseridtoken}></FileDragDropUploadArea>
                          </CardContent>
                      </Card>                               
                    } 
                    {!currentSelectedDocument &&
                      <Typography variant={"subtitle2"}>Bitte Dokument in der Liste auswählen</Typography>
                    }                            
                    {currentSelectedDocument && 
                      <Paper>
                        <FileItem categorieid={props.categorieid} currentSelectedDocument={currentSelectedDocument} firebaseuseridtoken={props.firebaseuseridtoken} closeFileItem={() => setCurrentSelectedDocument()}></FileItem>
                      </Paper>
                    } 
                  </div>
                </Split>            
            </BrowserView>
            <MobileView>
               {!currentSelectedDocument &&
                 <TextField fullWidth id={props.categorieid} label="Suche" variant="outlined" onChange={(event) => handleSearchChanged(event.target.value)}/>
               }
                {files && files.length > 0 && !currentSelectedDocument &&
                    <List className={classes.root}>
                      {loadFileList()}
                    </List>
                }
                {currentSelectedDocument &&
                  <FileItem categorieid={props.categorieid} currentSelectedDocument={currentSelectedDocument} firebaseuseridtoken={props.firebaseuseridtoken} closeFileItem={() => setCurrentSelectedDocument()}></FileItem>         
                }
            </MobileView>            
          </Grid>
        </Grid>
      </div>
    )
}

export default FileList;