import React from 'react';
import { CircularProgress, Typography } from '@material-ui/core';

export default function LoadingIndicator(props)
{
    return (
        <React.Fragment>
            <div style={{display: 'flex', justifyContent: 'center'}}>
                <CircularProgress color="primary"></CircularProgress>
            </div>
            <div style={{display: 'flex', justifyContent: 'center', paddingTop:10}}>
                <Typography variant="subtitle2">{props.loadingText}</Typography>
            </div>                
        </React.Fragment>
    )
}