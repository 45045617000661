import firebase from 'firebase/app';
import 'firebase/auth';

var firebaseConfig = {
  apiKey: "AIzaSyAwHfuOHwQUc-k5_Yw8p25PnTjt_TEy0xY",
  authDomain: "mydocs365.firebaseapp.com",
  projectId: "mydocs365",
  storageBucket: "mydocs365.appspot.com",
  messagingSenderId: "161410761319",
  appId: "1:161410761319:web:d593a06d37cdc96d690ac2",
  measurementId: "G-NCH4RFXPBT"
};
  firebase.initializeApp(firebaseConfig);
  firebase.auth().useDeviceLanguage();
  export const auth = firebase.auth;
