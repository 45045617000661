import React from 'react';
import axios from 'axios';
import {config} from './../config'
import { Button, Divider, Typography, Toolbar, Box, IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import GetAppIcon from '@material-ui/icons/GetApp';
import PdfViewer from './pdfviewer';
import ImageViewer from './imageviewer';
import CloseIcon from '@material-ui/icons/Close';

export default function FileItem(props)
{
    const [error, setError] = React.useState();

    function downloadFile(xFile)
    {  
      axios.get("https://7lbnc5r9w0.execute-api.eu-central-1.amazonaws.com/mydocs354FileApi", {headers: {'Authorization': `Bearer ` + props.firebaseUserToken}}).then((response) => {
        const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', xFile);
        document.body.appendChild(link);
        link.click();
        link.remove();
      }).catch((error) => 
      {
        setError(error.response.data)
      })   
    }

    function deleteFile(xFile)
    {
      axios.post(config.apiUrl+"files/delete",xFile, {headers: {'Authorization': `Bearer ` + props.firebaseuseridtoken}}).then((response) => {
        if (props.loadData)
            props.loadData();
      }).catch((error) => 
      {
        setError(error.response.data)
      })     
    }

    return (
        <div>
            <Toolbar>
              <IconButton color="primary" aria-label="upload picture" component="span" onClick={() => props.closeFileItem()}>
                <CloseIcon />
              </IconButton>                         
              <Typography variant="h6">
                {props.currentSelectedDocument.name}
              </Typography>             
              <Box flexGrow={1}></Box> 
              <Button
                variant="outlined"
                color="primary"
                startIcon={<GetAppIcon />}
                onClick={() => downloadFile(props.currentSelectedDocument.name)}
              >
                Download
              </Button>   
              <Button
                variant="outlined"
                color="primary"
                onClick={() => deleteFile(props.currentSelectedDocument)}
                startIcon={<DeleteIcon />}
                >
                Löschen
              </Button>                      
            </Toolbar>
            <Divider></Divider>
            <Box pt={5}></Box>
            {props.currentSelectedDocument.name.split('.').pop().toUpperCase() == "PDF" &&
                <PdfViewer file={props.currentSelectedDocument.name} categorieid={props.categorieid} firebaseuseridtoken={props.firebaseuseridtoken}></PdfViewer>
            }
            {props.currentSelectedDocument.name.split('.').pop().toUpperCase() == "JPG" &&
                <ImageViewer file={props.currentSelectedDocument.name} categorieid={props.categorieid} firebaseuseridtoken={props.firebaseuseridtoken}></ImageViewer>
            }        
            {props.currentSelectedDocument.name.split('.').pop().toUpperCase() == "JPEG" &&
                <ImageViewer file={props.currentSelectedDocument.name} categorieid={props.categorieid} firebaseuseridtoken={props.firebaseuseridtoken}></ImageViewer>
            }    
            {props.currentSelectedDocument.name.split('.').pop().toUpperCase() == "PNG" &&
                <ImageViewer file={props.currentSelectedDocument.name} categorieid={props.categorieid} firebaseuseridtoken={props.firebaseuseridtoken}></ImageViewer>
            }               
        </div>        
    )
}
