import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MailIcon from '@material-ui/icons/Mail';
import axios from 'axios';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import StarBorder from '@material-ui/icons/StarBorder';
import { Button, Collapse, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Menu, MenuItem } from '@material-ui/core';
import {
  Link
} from "react-router-dom";
import NewCategorieDialog from './newcategoriedialog';
import LoadingIndicator from '../helper/loadingindicator';
import {config} from './../config'

const initialState = {
  mouseX: null,
  mouseY: null,
};
const drawerWidth = 300;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));

export default function DrawerMenu(props) {
  const classes = useStyles();
  const [categories, setCategories] = React.useState();
  const [open, setOpen] = React.useState([]);
  const [state, setState] = React.useState(initialState);
  const [openNewCategorieDialog, setOpenNewCategorieDialog] = React.useState(false);
  const [currentContextMenuItemId, setCurrentContextMenuItemId] = React.useState()
  const [error, setError] = React.useState();

  function handleClick(xId)  {
    if (open.indexOf(xId) === -1)
    {
        setOpen([...open, xId]);
        localStorage.setItem('mydocs365_openedMenuItems', JSON.stringify([...open, xId]));
    }else
    {
        const reducedArr = [...open];
        reducedArr.splice(open.indexOf(xId), 1);  
        setOpen(reducedArr)
        localStorage.setItem('mydocs365_openedMenuItems', JSON.stringify(reducedArr));
    }
  };

  const handleClickMenu = (event) => {
    if (event.target.offsetParent && event.target.offsetParent.id !== "")
    {
      setCurrentContextMenuItemId(event.target.offsetParent.id);
    }else
    {
      setCurrentContextMenuItemId();
    }
    event.preventDefault();
    setState({
      mouseX: event.clientX - 2,
      mouseY: event.clientY - 4,
    });
  };
  
  useEffect(() => {
    if (categories && categories.length > 0 && localStorage.getItem('mydocs365_openedMenuItems'))
    {
      setOpen(JSON.parse(localStorage.getItem('mydocs365_openedMenuItems')))
    }
  },[categories])

  const handleCloseMenu = () => {
    setState(initialState);
  };

  useEffect(() => {
      loadCategories();
  }, [])

  useEffect(() => {
    if (props.updateCategorieView)
    {
      loadCategories();
    }
  },[props.updateCategorieView])

  function loadCategories()
  {
      axios.get(config.apiUrl+"categories", {headers: {'Authorization': `Bearer ` + props.firebaseUserToken}}).then((response) => 
      {
        if (response.data.Items && response.data.Items.length > 0)
          setCategories(response.data.Items[0].categories)
      }
      );
  }

  function handleSaveNewCategorieSuccessfull(xBezeichnung, xParentCategorieId)
  {
    let newCategories = [...categories];
    if (!xParentCategorieId)
    {
      newCategories.push({ "id": xBezeichnung, "bezeichnung": xBezeichnung, "categories": []});  
    }else
    {
      for (let index = 0; index < newCategories.length; index++) {
        if (newCategories[index].id === xParentCategorieId)
        {
          newCategories[index].categories.push({ "id": xParentCategorieId+"/"+xBezeichnung, "bezeichnung": xBezeichnung, "categories": []});        
          break;
        }
        if (newCategories[index].categories && newCategories[index].categories.length > 0)
        {
          findSubCategories(newCategories[index].categories, xBezeichnung, xParentCategorieId);
        }
      }
    }
    axios.delete(config.apiUrl+"categories/menu", {headers: {'Authorization': `Bearer ` + props.firebaseUserToken}}).then((response) => 
    {
      axios.post(config.apiUrl+"categories", {"id":"menu", "categories": newCategories},{headers: {'Authorization': `Bearer ` + props.firebaseUserToken}}).then((response) => 
      {
        setCategories(newCategories);
        setOpenNewCategorieDialog(false);
      });
    });
  }

  function findSubCategories(xParentCategories, xBezeichnung, xParentCategorieId)
  {
    for (let index = 0; index < xParentCategories.length; index++) {
      if (xParentCategories[index].id === xParentCategorieId)
      {
        xParentCategories[index].categories.push({ "id": xParentCategorieId+"/"+xBezeichnung, "bezeichnung": xBezeichnung, "categories": []});        
        break;
      }
      if (xParentCategories[index].categories && xParentCategories[index].categories.length > 0)
      {
        findSubCategories(xParentCategories[index].categories, xBezeichnung, xParentCategorieId)
      }
    }    
  }

  function deleteCategorie()
  {
    axios.get(config.apiUrl+"categories/delete/"+currentContextMenuItemId,  {headers: {'Authorization': `Bearer ` + props.firebaseUserToken}}).then((response) => 
      loadCategories()
    ).catch((error) => 
    {
      setError(error.response.data)
    })
  }

  function GetExpandIcon(xId)
  {
       if (open.indexOf(xId) > -1)
       {
            return (<ExpandLess onClick={() => handleClick(xId)} />);
       }
       return (<ExpandMore onClick={() => handleClick(xId)} />);
  }

  function LoadTreeViewItems()
  {
      const items = categories.map((categorie) => 
          <Link to={"/categories/"+categorie.id} style={{ textDecoration: 'none', color: 'black' }} key={categorie.id}>
                <ListItem button key={categorie.id} id={categorie.id}>
                    <ListItemIcon><MailIcon/></ListItemIcon>
                    <ListItemText primary={`${categorie.bezeichnung}`} />
                    {/* <ListItemText primary={`${categorie.bezeichnung} (${categorie.anzahlDocs})`} /> */}
                    {categorie.categories && categorie.categories.length > 0 &&
                     GetExpandIcon(categorie.id)
                    }
                </ListItem>
                {categorie.categories && categorie.categories.length > 0 &&
                  LoadSubTreeViewItems(categorie, 1)
                }
          </Link>
      )
      return items;
  }

function isOpenMenuItem(xId)
{
  return open.indexOf(xId) > -1;
}

  function LoadSubTreeViewItems(xCatagorie, xCounter)
  {
      const Subitems = xCatagorie.categories.map((subCategorie) => 
          <Link to={"/categories/"+subCategorie.id} style={{ textDecoration: 'none', color: 'black' }} key={subCategorie.id}>
              <Collapse in={isOpenMenuItem(xCatagorie.id)} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    <ListItem button style={{paddingLeft: 24 * xCounter }} key={subCategorie.id} id={subCategorie.id}>
                        <ListItemIcon>
                        <StarBorder />
                        </ListItemIcon>
                        {/* <ListItemText primary={`${subCategorie.bezeichnung} (${subCategorie.anzahlDocs})`} /> */}
                        <ListItemText primary={`${subCategorie.bezeichnung}`} />
                        {subCategorie.categories && subCategorie.categories.length > 0 &&
                          GetExpandIcon(subCategorie.id)
                        }
                    </ListItem>
                </List>
                {subCategorie.categories && subCategorie.categories.length > 0 &&
                  LoadSubTreeViewItems(subCategorie, xCounter+1)}
            </Collapse>
          </Link>      
      )
      return Subitems;
  }

  if (!categories)
  {
      return <LoadingIndicator loadingText={"Kategorieansicht wird geladen..."}></LoadingIndicator>
  }

  return (
    <Drawer
            className={classes.drawer}
            variant="persistent"
            open={props.mobileOpen}
            classes={{
            paper: classes.drawerPaper,
            }}
        >
        <Toolbar></Toolbar>
        <Dialog aria-labelledby="simple-dialog-title" open={error && error !== ""}>
          <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
            Fehler beim Löschen der Kategorie
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {error}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={() => setError("")} color="primary">
              OK
            </Button>
          </DialogActions>
        </Dialog>          
        <div className={classes.drawerContainer} style={{ cursor: 'context-menu' }}>
          <List>
              <Link to={"/dashboard"} style={{ textDecoration: 'none', color: 'black' }}>
                  <ListItem button key={"Dashboard"}>
                      <ListItemIcon><MailIcon/></ListItemIcon>
                      <ListItemText primary={"Dashboard"} />
                  </ListItem>
              </Link>
          </List>
          <Divider />
          <List style={{minHeight: 400}} onContextMenu={(event) => handleClickMenu(event)}>
              {categories && 
                  LoadTreeViewItems()         
              }
          </List>
        </div>
        <Menu
          keepMounted
          open={state.mouseY !== null}
          onClose={handleCloseMenu}
          anchorReference="anchorPosition"
          anchorPosition={
            state.mouseY !== null && state.mouseX !== null
              ? { top: state.mouseY, left: state.mouseX }
              : undefined
          }
        >
        {(!currentContextMenuItemId || currentContextMenuItemId === "") &&
          <MenuItem onClick={() => {setOpenNewCategorieDialog(true); handleCloseMenu();}}>Neue Haupt-Kategorie hinzufügen</MenuItem>
        }
        {currentContextMenuItemId && currentContextMenuItemId !== "" &&
          <MenuItem onClick={() => {setOpenNewCategorieDialog(true); handleCloseMenu();}}>Neue Unter-Kategorie hinzufügen</MenuItem>
        }
        {currentContextMenuItemId && currentContextMenuItemId !== "" &&
          <MenuItem onClick={() => {deleteCategorie(); handleCloseMenu();}}>Kategorie löschen</MenuItem>
        } 
        </Menu>
        <NewCategorieDialog openDialog={openNewCategorieDialog} firebaseUserToken={props.firebaseUserToken} handleCloseDialog={() => setOpenNewCategorieDialog(false)} currentCategorieId={currentContextMenuItemId} handleSaveNewCategorieSuccessfull={handleSaveNewCategorieSuccessfull}></NewCategorieDialog>
    </Drawer>
  );
}
