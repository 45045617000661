import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Axios from 'axios';
import { auth } from '../../firebase';

const useStyles = makeStyles((theme) => ({
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
  }));

function SignUp(props) {
    const classes = useStyles();
    const [email,
        setEmail] = useState("");
    const [password,
        setPassword] = useState("");
    const [vorname, setVorname] = useState("");
    const [name, setName] = useState("");
        
    async function doSignUp(event) {
        event.preventDefault();
        try {
            auth().createUserWithEmailAndPassword(email, password).then(function() {
              auth().currentUser.sendEmailVerification({handleCodeInApp: false,url: 'https://reichsstetthexa-events.de'}).then(function() {
                auth()
                .currentUser
                  .getIdToken()
                  .then((idToken) => {
                    var newUser = {id: auth().currentUser.uid, vorname: vorname, name: name, email: email, editmode: false};
                    Axios
                      .post('/api/user',newUser, {
                      headers: {
                          'Authorization': `Bearer ` + idToken
                      }
                      })
                      .then(function (response) {
                        props.signUpSuccess();
                      })
                      .catch(function (error) {
                          console.log(error);
                      })   
                  })  
              })
            })        
        } catch (error) {
          props.setError(error.message);
        }
    }

    return (
        <form className={classes.form} noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                autoComplete="fname"
                name="vorname"
                variant="outlined"
                required
                fullWidth
                id="vorname"
                label="Vorname"
                autoFocus
                onChange={(event) => setVorname(event.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="name"
                label="Name"
                name="name"
                autoComplete="lname"
                onChange={(event) => setName(event.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="email"
                label="Email Adresse"
                name="email"
                autoComplete="email"
                onChange={(event) => setEmail(event.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                name="password"
                label="Passwort"
                type="password"
                id="password"
                autoComplete="current-password"
                onChange={(event) => setPassword(event.target.value)}
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="secondary"
            className={classes.submit}
            onClick={doSignUp}
          >
            Account erstellen
          </Button>
          <Grid container justify="flex-end">
            <Grid item>
              <Link href="#" variant="body2" onClick={() => props.setSignUpMode(false)}>
                Account bereits vorhanden? Login
              </Link>
            </Grid>
          </Grid>
        </form>
      );
}

export default SignUp;