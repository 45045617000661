import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import axios from 'axios';
import {config} from './../config'

export default function NewCategorieDialog(props)
{
    const [open, setOpen] = React.useState(false);
    const [bezeichnung, setBezeichnung] = React.useState("");

    React.useEffect(() => {
        if (props.openDialog)
            setOpen(true);
    },[props.openDialog])
  
    const handleClose = () => {
      setOpen(false);
      props.handleCloseDialog(false);
    };

    function handleSaveNewCategorie()
    {
      debugger;
      props.handleSaveNewCategorieSuccessfull(bezeichnung, props.currentCategorieId);
    }

    return (
      <div>
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth={true} maxWidth={"sm"}>
          {!props.currentCategorieId && 
            <DialogTitle id="form-dialog-title">Neue Kategorie hinzufügen</DialogTitle>
          }
          {props.currentCategorieId && 
            <DialogTitle id="form-dialog-title">Neue Kategorie zu {props.currentCategorieId} hinzufügen</DialogTitle>
          }          
          <DialogContent>
            {/* <DialogContentText>
                Neue Kategorie zu {props.currentCategorieId} hinzufügen
            </DialogContentText> */}
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Kategorie-Bezeichnung"
              type="text"
              fullWidth
              onChange={(event) => setBezeichnung(event.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Abbrechen
            </Button>
            <Button onClick={() => handleSaveNewCategorie()} color="primary">
              Hinzufügen
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
}