
import React, { useEffect } from 'react';
import axios from 'axios';
import {config} from '../config'
import { pdfjs } from 'react-pdf';
import { Box, CircularProgress } from '@material-ui/core';
import LoadingIndicator from '../helper/loadingindicator';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default function ImageViewer(props)
{
    const [imageBinaryData, setImageBinaryData] = React.useState();
    const [loading, setLoading] = React.useState(true);
    useEffect(() => {
        loadImageBinary();
    },[props.file])

    function loadImageBinary()
    {
        axios.get("https://7lbnc5r9w0.execute-api.eu-central-1.amazonaws.com/"+props.file, {headers: {'Authorization': `Bearer ` + props.firebaseUserToken}}).then((response) => {
        setImageBinaryData(Buffer(response.data.Body).toString('base64'));
          setLoading(false);
        }).catch((error) => 
        {

        })           
    }

    return (
     <Box display="flex" justifyContent="center" style={{maxHeight:600, minHeight:600, overflow: 'scroll', alignContent: "center", align: "center" }}>
         {loading && 
            <LoadingIndicator loadingText={props.file}></LoadingIndicator>
         }
         {!loading &&
            <img style={{height: 600, objectFit: "cover"}} src={`data:image/jpeg;base64,${imageBinaryData}`}></img>
         }
     </Box>
    )
}

