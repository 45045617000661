import React, {useState, useEffect} from 'react';
import { auth } from '../../firebase';
import {passwordReset} from '../../helper/auth';
import Avatar from '@material-ui/core/Avatar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import SignUp from './signup';
import Signin from './signin';
import PasswordReset from './passwordreset';
import { Button } from '@material-ui/core';
import { Redirect } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    paper: {
      marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.primary.main,
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
  }));

function Login(props) {
    const classes = useStyles();
    const [signUpMode, setSignUpMode] = useState(false);
    const [errorText, setErrorText] = useState("");
    const [waitForEmailverification, setWaitForEmailverification] = useState(false);
    const [signInSuccess, setSignInSuccess] = useState(false);
    const [passwordResetMode, setPasswordResetMode] = useState(false);
    const [passwordResetMailSend, setPasswordResetMailSend] = useState(false);
    const [userLoggedIn, setUserLoggedIn] = useState(false);

    useEffect(() => 
    {
        var user =auth().currentUser;
        if (user && user.emailVerified) {
          setUserLoggedIn(true);
        }
    },[]);

    function signIn(xEmail, xPassword) {
        auth().signInWithEmailAndPassword(xEmail, xPassword).then(function() {
            setSignInSuccess(true);
            // if (auth().currentUser.emailVerified)
            // {
            //     setSignInSuccess(true);
            // }else
            // {
            //     setErrorText("Deine E-Mailadresse wurde noch nicht bestätigt")
            // }
          }).catch(function(error) {
            setErrorText(error.message)
            debugger;
            // Handle Errors here.
            var errorCode = error.code;
            var errorMessage = error.message;
            // ...
          });
    }

    function signUpMethod(xValue)
    {
        setSignUpMode(xValue);
        setErrorText();
    }

    function setError(xError)
    {
        setErrorText(xError);
    }

    function handleSignUpSuccess()
    {
        setWaitForEmailverification(true);
    }

    function handlePasswordReset(xEmail)
    {
        passwordReset(xEmail);
        setPasswordResetMailSend(true);
    }

    if (signInSuccess || userLoggedIn)
    {
        return <Redirect to="/"></Redirect>
    }

    if (waitForEmailverification)
    {
        return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5" gutterBottom>
                  myDocs365.de
                </Typography>
                <Typography variant="h6" gutterBottom>Vielen Dank für Deine Anmeldung!</Typography>   
                <Typography variant="h6" gutterBottom>Wir haben Dir eine E-Mail zur Bestätigung Deiner E-Mailadresse geschickt. Folge den Anweisungen in der E-Mail, um Deine E-Mailadresse zu bestätigen.</Typography>  
                <Button variant="contained" color="primary" onClick={() => {setWaitForEmailverification(false);setSignUpMode(false);}}>Fertig</Button>
            </div>  
        </Container>);
    }

    if (passwordResetMailSend)
    {
        return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5" gutterBottom>
                  myDocs365.de
                </Typography>
                <Typography variant="h6" gutterBottom>E-Mail zum Zurücksetzen des Passwortes gesendet</Typography>
                <Typography variant="body1" gutterBottom>Eine E-Mail wurde an Deine E-Mailadresse gesendet. Folge den Anweisungen in der E-Mail, um Dein Passwort zurückzusetzen.</Typography>   
                <Button variant="contained" color="primary" onClick={() => {setPasswordResetMode(false);setPasswordResetMailSend(false);}}>Fertig</Button>  
            </div>  
        </Container>);
    }

    return (
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <div className={classes.paper}>
            <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              myDocs365.de
            </Typography>
            {/* {!currentUserLoaded &&
                <CircularProgress></CircularProgress>           
            }            */}
            {!signUpMode && !passwordResetMode && 
                <Signin setSignUpMode={signUpMethod} signIn={signIn} passwordResetMode={() => setPasswordResetMode(true)}></Signin>            
            }
            {signUpMode && !passwordResetMode &&
                <SignUp setSignUpMode={signUpMethod} setError={setError} signUpSuccess={handleSignUpSuccess}></SignUp>            
            }
            {/* {passwordResetMode &&
                <PasswordReset passwordReset={handlePasswordReset} cancelPasswordReset={() => setPasswordResetMode(false)}></PasswordReset>
            } */}
          </div>
          <Box mt={8}>
            <Typography variant={"h5"}>{errorText}</Typography>
          </Box>
        </Container>
      );
}

export default Login;