import React, {useState, useEffect} from 'react';
import {auth} from './firebase';
import './App.css';
import {BrowserRouter as Router, Route} from 'react-router-dom';
import PrivateRoute from './privateroute';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Login from './components/login/login';
import LoadingIndicator from './helper/loadingindicator';
import { Box } from '@material-ui/core';
import MyDocs from './mydocs';

const theme = createMuiTheme({
typography: {
    useNextVariants: true,
    },
  palette: {
    primary: {
      main: '#000000',
    },
    secondary: {
      main: '#ffcc00',
    }
  }
});

function App() {
    const [firebaseUser, setFirebaseUser] = useState();
    const [firebaseUserToken, setFirebaseUserToken] = useState();
    const [authorized, setAuthorized] = useState(false);
    const [loginDataChecked, setLoginDataChecked] = useState(false);

    useEffect(() => {
        auth().onAuthStateChanged(xUser => {
            if (xUser) {
                xUser.getIdToken().then((idToken) => {
                    //loadUser(idToken, xUser.uid);
                    setFirebaseUserToken(idToken);
                    setAuthorized(true);
                    setFirebaseUser(xUser);
                    setLoginDataChecked(true);
                  })
            }else
            {
                setAuthorized(false);
                setLoginDataChecked(true);
            }
        })
    }, [])

    
    if (!loginDataChecked)
    {
        return (
            <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="100vh"
            >
                <LoadingIndicator loadingText="Anwendung wird geladen..."></LoadingIndicator>
            </Box>
        );
    }
    return (
            <MuiThemeProvider theme={theme}>
                <Router>
                    <div>
                        <PrivateRoute
                            exact
                            path="/"
                            authorized={authorized}
                            firebaseUserToken={firebaseUserToken}
                            component={MyDocs}/>
                        <Route path="/login" component={Login}/>
                    </div>
                </Router>
            </MuiThemeProvider>
    );
}
export default App;
