
import React, { useEffect } from 'react';
import axios from 'axios';
import {config} from './../config'
import { Document, Page } from 'react-pdf';
import { pdfjs } from 'react-pdf';
import { CircularProgress, Box, Typography } from '@material-ui/core';
import LoadingIndicator from '../helper/loadingindicator';
import AWS from 'aws-sdk'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const S3_BUCKET ='mydocs365';
const REGION ='eu-central-1';

AWS.config.update({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_ID
})

const myBucket = new AWS.S3({
  params: { Bucket: S3_BUCKET},
  region: REGION,
})

export default function PdfViewer(props)
{
    console.log(process.env.REACT_AWS_ACCESS_KEY_ID)
    const [pdfBinaryData, setPdfBinaryData] = React.useState();
    const [numPages, setNumPages] = React.useState(null);
    const [pageNumber, setPageNumber] = React.useState(1);
    const [loading, setLoading] = React.useState(true);

    useEffect(() => {
        loadPdfBinary();
    },[props.file])

    function loadPdfBinary()
    {
      const params = {
          Bucket: S3_BUCKET,
          Key: props.categorieid+"/"+props.file
      };
      myBucket.getSignedUrl('getObject', {
        Bucket: S3_BUCKET,
        Key: props.categorieid+"/"+props.file,
        Expires: 60
       }, function(err, url) {
        setPdfBinaryData(url);
        setLoading(false);
        });
    }

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
      }

    return (
    <Box display="flex" justifyContent="center" style={{maxHeight:630, minHeight:630, overflow: 'scroll', alignContent: "center", align: "center" }}>
        {loading && 
            <LoadingIndicator loadingText={props.file}></LoadingIndicator>
        }
        {!loading && 
            <Document file={{url: pdfBinaryData}} onLoadSuccess={onDocumentLoadSuccess} onSourceError={console.error} onLoadError={console.error}>
                {Array.from(
                    new Array(numPages),
                    (el, index) => (
                    <Page
                        scale={1.5}
                        key={`page_${index + 1}`}
                        pageNumber={index + 1}
                    />
                    ),
                )}
                </Document>  
         }
       
     </Box>
    )
}

